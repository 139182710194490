import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#0f1c47",
        secondary: "#0000ff",
        info: "#007fff",
        accent: "#566e99",
        accentLight: "#edf6ff",
        success: "#00e069",
        warning: "#ffd100",
        error: "#ff0000",
        background: "#fafbfc",
        borders: "#e9eef2",
        base: "#666666",
        baseSubtle: "#999999",
        legendBlue: "#686de0",
        legendRed: "#eb4d4b",
        legendGreen: "#badc58",
        legendYellow: "#f9ca24",
        legendDarkBlue: "#30336b",
        legendAqua: "#7ed6df",
        inactive: "#f2f2f2",
        pink: "#ff05c2",
        accentPink: "#ffeefd",
        highlight: "#27ae60"
      },
      dark: {
        primary: "#007fff",
        secondary: "#0000ff",
        success: "#8cc63f",
        warning: "#ffd100",
        error: "#c1272d",
        background: "#13234f",
        accent: "#b3b3b3",
        vocusPrimary: "#0f1c47",
        themeYellow: "#ff9c00",
        themeOrange: "#ff6600",
        themePink: "#ff05c2",
        themeGreen: "#37AE4B",
        themeRed: "#ed1c24",
        themeBlue: "#007fff",
        themeInactive: "#252f4d"
      }
    },
    icons: {
      iconfont: "fa"
    }
  }
});
