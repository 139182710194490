var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"background","dark":"","flat":"","height":"70px"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold ml-4 d-flex"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"info"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-4",attrs:{"small":"","dark":"","icon":"","to":"/"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-view-dashboard")])],1)]}}])},[_c('span',[_vm._v("Wallboards")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-4",attrs:{"small":"","dark":"","icon":"","to":"/agents"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-headset")])],1)]}}])},[_c('span',[_vm._v("Agents")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","icon":""},on:{"click":_vm.togglePhone}},'v-btn',attrs,false),on),[(_vm.open)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-phone-slash")]):_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-phone")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.open ? "Close" : "Open")+" softphone")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":"","dark":"","icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-sign-out-alt")])],1)]}}])},[_c('span',[_vm._v("Logout")])]),_c('div',{staticClass:"d-flex align-center ml-10 mr-4"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Logo","contain":"","src":require('../assets/logo.png'),"transition":"scale-transition","max-height":"30","max-width":"100"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }