<template>
  <div v-if="!loading">
    <v-container fluid class="pa-6">
      <v-card flat color="background" class="wallboard-nav mb-6 px-4 py-3">
        <v-row no-gutters align="center">
          <v-col cols="flex">
            <span v-if="!editing && !computedWallboards.length">
              Click edit to add wallboards
            </span>
            <span v-else>
              <v-autocomplete
                v-if="editing"
                v-model="wallboardIds"
                :items="allWallboards"
                item-text="name"
                item-value="id"
                label="Select wallboards"
                placeholder="Select wallboards"
                flat
                solo
                dense
                chips
                deletable-chips
                multiple
                hide-details="true"
                background-color="vocusPrimary"
              >
              </v-autocomplete>
              <v-tabs
                v-else
                center-active
                v-model="tab"
                color="white"
                background-color="transparent"
                height="32px"
              >
                <v-tab
                  v-for="(wallboard, index) in computedWallboards"
                  :key="index"
                >
                  {{ wallboard.name }}
                  <v-progress-circular
                    v-if="rotate && index === tab"
                    :size="15"
                    :value="rotateProgress"
                    width="3"
                    color="white"
                    class="ml-2"
                  ></v-progress-circular>
                </v-tab>
              </v-tabs>
            </span>
          </v-col>

          <v-col cols="auto">
            <v-tooltip v-if="editing" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  height="38"
                  width="38"
                  elevation="0"
                  color="info"
                  class="float-left ml-4"
                  @click="editing = !editing"
                >
                  <v-icon x-small>fa-check</v-icon>
                </v-btn>
              </template>
              <span>Done</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  height="32"
                  width="32"
                  class="float-left ml-4"
                  @click="editing = !editing"
                >
                  <v-icon x-small>fa-pen</v-icon>
                </v-btn>
              </template>
              <span>Edit Wallboards</span>
            </v-tooltip>
          </v-col>

          <v-col v-if="computedWallboards.length > 1" cols="auto" class="pl-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-switch
                    v-model="rotate"
                    inset
                    color="info"
                    hide-details="auto"
                    class="mt-0 pt-0"
                  ></v-switch>
                </span>
              </template>
              <span>Auto rotate</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(wallboard, index) in computedWallboards"
          :key="index"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
              v-for="(queue, qIndex) in wallboard.queues"
              :key="qIndex"
            >
              <v-card color="background fill-screen" elevation="0">
                <v-card-text>
                  <v-sheet
                    :color="
                      queue.agents_online < 1 ? 'themeInactive' : colors[qIndex]
                    "
                    rounded
                    class="white--text pb-1 feature-sheet"
                  >
                    <v-card-title
                      :title="queue.display_name || queue.name"
                      class="queue-name font-weight-bold"
                      >{{ queue.display_name || queue.name }}</v-card-title
                    >
                    <v-card-subtitle
                      class="white--text font-weight-bold pb-0 pt-2"
                    >
                      Calls waiting
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row no-gutters align="end">
                        <v-col cols="auto">
                          <h1
                            :class="`queue-count font-weight-bold d-inline-block mr-2 ${
                              update ? queue.callChange : ''
                            }`"
                          >
                            {{ queue.contacts_in_queue || 0 }}
                          </h1>
                        </v-col>
                        <v-col cols="flex">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-on="on"
                                v-bind="attrs"
                                class="font-weight-bold available-text"
                              >
                                <v-icon small class="pr-1 nudge-up-one"
                                  >fa-headset</v-icon
                                >{{ agentAvailability(queue) }}
                              </span>
                            </template>
                            <span>Available Agents</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-sparkline
                      v-show="false"
                      :value="[423, 446, 675, 510, 590, 610, 760, 650]"
                      color="white"
                      height="100"
                      stroke-linecap="round"
                      smooth="20"
                    >
                    </v-sparkline>
                  </v-sheet>
                  <div
                    :class="`sub-sheet mt-n4 pt-4 bg-${
                      colors[qIndex]
                    } queue-alert ${queue.agents_online < 1 ? 'inactive' : ''}`"
                  >
                    <v-card-text class="white--text">
                      <h4>Oldest Call Wait Time</h4>
                      <div class="text-h3 font-weight-bold">
                        {{ milliToSecs(queue.oldest_contact_age)
                        }}<span class="text-h6 font-weight-bold">sec</span>
                      </div>
                    </v-card-text>
                  </div>
                </v-card-text>
                <v-row
                  no-gutters
                  class="px-4 pb-4 stats-wrapper"
                  align="center"
                >
                  <v-col cols="12">
                    <v-card-text>
                      <h4>Calls Offered</h4>
                      <div class="text-h3 font-weight-bold">
                        {{ queue.contacts_queued || 0 }}
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-card-text>
                      <h4>Calls Missed</h4>
                      <div class="text-h3 font-weight-bold">
                        {{
                          abandonPercent(
                            queue.contacts_abandoned,
                            queue.contacts_queued
                          )
                        }}<span class="text-h6 font-weight-bold">%</span>
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-card-text>
                      <h4>Average Speed of Answer</h4>
                      <div class="text-h3 font-weight-bold">
                        {{ secOrMins(queue.queue_answer_time).value
                        }}<span class="text-h6 font-weight-bold">{{
                          secOrMins(queue.queue_answer_time).label
                        }}</span>
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-card-text>
                      <h4>Average Handling Time</h4>
                      <div class="text-h3 font-weight-bold">
                        {{ secsToMins(queue.handle_time)
                        }}<span class="text-h6 font-weight-bold">min</span>
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <div :class="`date-box ${pinned ? 'pinned' : ''}`">
      <span class="update-time">Last updated: {{ dataSnapshotTime }}</span>
      <v-btn icon small class="ml-1" @click="pinned = !pinned"
        ><v-icon small :color="pinned ? 'primary' : 'white'"
          >fa-thumbtack</v-icon
        ></v-btn
      >
    </div>
  </div>
</template>

<script>
import { round } from "lodash";

export default {
  data() {
    return {
      menu: false,
      tab: 0,
      rotate: false,
      rotateProgress: 5,
      rotating: null,
      anotherFunc: null,
      editing: false,
      wallboardIds: [],
      update: false,
      pinned: false,
      metrics: [],
      colors: [
        "themeYellow",
        "themeOrange",
        "themePink",
        "themeGreen",
        "themeRed",
        "themeBlue"
      ]
    };
  },
  metaInfo() {
    return {
      title: this.$route.name
    };
  },
  beforeDestroy() {
    clearInterval(this.rotating);
    clearInterval(this.anotherFunc);
  },
  mounted() {
    this.wallboardIds = this.$store.getters.userWallboardIds;
    this.metrics = this.$store.getters.metrics;
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    dataSnapshotTime() {
      return this.$store.getters.dataSnapshotTime;
    },
    allWallboards() {
      return this.$store.getters.allWallboards;
    },
    computedWallboards() {
      const wallboardsToDisplay = this.wallboardIds.map(item => {
        return this.allWallboards.find(x => x.id === item);
      });
      return wallboardsToDisplay.map(wallboard => {
        return {
          id: wallboard.id,
          name: wallboard.name,
          queues: wallboard.queues.map(queue => {
            const x = this.metrics.find(x => x.id === queue.id);
            if (x === undefined) {
              return queue;
            } else {
              return x;
            }
          })
        };
      });
    }
  },
  methods: {
    playSound() {
      const audio = new Audio(require("@/assets/ding.mp3"));
      audio.play();
    },
    secsToMins(timeInSecs) {
      if (timeInSecs === undefined) {
        return 0;
      } else {
        return round(timeInSecs / 60, 1);
      }
    },
    secOrMins(timeInSecs) {
      let object = {
        label: "sec",
        value: timeInSecs || 0
      };
      if (timeInSecs > 60) {
        object.label = "min";
        object.value = round(timeInSecs / 60, 1);
      }
      return object;
    },
    milliToSecs(timeInMillis) {
      if (timeInMillis === undefined) {
        return 0;
      } else {
        return round(timeInMillis / 1000);
      }
    },
    abandonPercent(abandoned, volume) {
      if (abandoned == undefined || volume === undefined) {
        return 0;
      } else {
        return round((abandoned / volume) * 100);
      }
    },
    agentAvailability(queue) {
      const available = queue.agents_available || 0;
      const online = queue.agents_online || 0;
      if (available === 0) {
        return "No Agents Available";
      } else {
        return `${available}/${online} Available`;
      }
    }
  },
  watch: {
    "$store.state.userWallboardIds": function(value) {
      this.wallboardIds = value;
    },
    "$store.state.metrics": function(to, from) {
      this.update = true;
      this.metrics = to.map(item => {
        const prevCalls = from.find(x => x.id === item.id);
        if (prevCalls !== undefined) {
          let callChange = "";
          if (item.contacts_in_queue > prevCalls.contacts_in_queue) {
            callChange = "count-increase";
          } else if (item.contacts_in_queue < prevCalls.contacts_in_queue) {
            callChange = "count-decrease";
          }
          return {
            ...item,
            callChange
          };
        } else {
          return item;
        }
      });
      setTimeout(() => {
        this.update = false;
      }, 1000);
    },
    rotate(value) {
      if (value === true) {
        this.anotherFunc = setInterval(() => {
          this.rotateProgress += 5;
        }, 250);

        this.rotating = setInterval(() => {
          this.tab++;
          this.rotateProgress = 5;
        }, 5000);
      } else {
        clearInterval(this.rotating);
        clearInterval(this.anotherFunc);
        this.rotating = null;
      }
    },
    editing(value) {
      if (value === false) {
        this.$store.dispatch("updateUserWallboardIds", this.wallboardIds);
      }
    }
  }
};
</script>

<style>
.fill-screen {
  height: calc(100vh - 198px); /** navbar, padding, wallboard-nav = 198px  */
  min-height: calc(326px + 470px); /** Feature sheet + stats-wrapper min */
}
.stats-wrapper {
  height: calc(100vh - 198px - 326px); /** Feature sheet = 326px */
  min-height: 470px;
}
.queue-count {
  font-size: 4.75rem;
  line-height: 0.8;
}
.queue-count.count-increase {
  animation: countUp ease 0.6s;
}
.queue-count.count-decrease {
  animation: countDown ease 0.6s;
}
@keyframes countUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes countDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.wallboard-nav .v-tab {
  text-transform: none;
  letter-spacing: 0.35px;
  z-index: 2;
}
.wallboard-nav .v-tab.v-tab--active {
  font-weight: bold;
  letter-spacing: normal;
}
.wallboard-nav .v-tabs-slider-wrapper {
  top: 0;
  height: 32px !important;
}
.wallboard-nav .v-tabs-slider {
  background-color: #0000ff;
  border-radius: 4px;
}
.wallboard-nav .v-tab:before {
  border-radius: 4px;
}
.wallboard-nav .v-tab .v-ripple__container {
  border-radius: 4px;
  overflow: hidden;
}
.v-card__title.queue-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.75rem;
  min-height: 72px;
  padding-bottom: 0;
  margin-bottom: 16px;
  word-break: normal;
}
.theme--dark.v-sheet.sub-sheet {
  background-color: rgba(255, 255, 255, 0.08);
}
.v-sheet.feature-sheet {
  position: relative;
  z-index: 2;
}
.sub-sheet {
  position: relative;
  z-index: 1;
}
.sub-sheet::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  opacity: 0.3;
  z-index: -1;
  transition: 0.3s ease;
}
.sub-sheet.bg-themeYellow::before {
  background-color: #ff9c00;
}
.sub-sheet.bg-themeOrange::before {
  background-color: #ff6600;
}
.sub-sheet.bg-themePink::before {
  background-color: #ff05c2;
}
.sub-sheet.bg-themeGreen::before {
  background-color: #37ae4b;
}
.sub-sheet.bg-themeRed::before {
  background-color: #ed1c24;
}
.sub-sheet.bg-themeBlue::before {
  background-color: #007fff;
}
.sub-sheet.inactive::before {
  background-color: #252f4d;
}
.sub-sheet.queue-alert::before {
  opacity: 0.75;
}
.date-box {
  position: fixed;
  transform: translateX(-50%);
  bottom: -40px;
  left: 50%;
  padding: 10px 20px;
  background: #0000ff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease;
}
.date-box:hover {
  bottom: 0;
}
.date-box.pinned {
  bottom: 0 !important;
}
.available-text {
  font-size: 18px;
}
@media only screen and (min-width: 1264px) and (max-width: 1575px) {
  .queue-count {
    font-size: 58px;
  }
  .available-text {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
