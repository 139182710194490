import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "@/firebase";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Queue Wallboards",
    meta: {
      icon: "mdi-view-dashboard"
    },
    component: Home
  },
  {
    path: "/agents",
    name: "Agents",
    meta: {
      icon: "fa-headset"
    },
    redirect: "/"
    // component: () =>
    //   import(/* webpackChunkName: "agents" */ "../views/Agents.vue")
  },
  {
    path: "/_ss",
    name: "Admin Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Admin.vue"),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "*",
    name: "Not found",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const allowAnonymous = to.matched.some(record => record.meta.allowAnonymous);

  if (!allowAnonymous && currentUser) {
    store.commit("setUser", currentUser);
    next();
  } else if (to.path == "/login" && currentUser) {
    store.commit("setUser", currentUser);
    next("/");
  } else if (!allowAnonymous && !currentUser) {
    if (to.fullPath == "/") {
      next("login");
    } else {
      next({
        path: "login",
        query: { redirect: to.fullPath }
      });
    }
  } else {
    next();
  }
});

export default router;
