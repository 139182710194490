<template>
  <v-app>
    <Header v-if="isAuthenticated" :open="open" @phoneOpen="open = $event" />

    <v-main>
      <router-view />
    </v-main>
    <Ccp :open="open" @phoneOpen="open = $event" />
  </v-app>
</template>

<script>
import Header from "@/components/TheHeader";
import Ccp from "@/components/Ccp";

export default {
  name: "App",
  data() {
    return {
      open: false
    }
  },
  components: {
    Header,
    Ccp
  },
  created() {
    this.$store.dispatch("getVersionUpdate");
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.user;
    }
  },
  watch: {
    "$store.state.refresh": function () {
      window.location.reload();
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Moderat";
  src: url("./styles/font/Moderat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Moderat";
  src: url("./styles/font/Moderat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Moderat";
  src: url("./styles/font/Moderat-Regular-Italic.ttf") format("truetype");
  font-weight: 400 normal;
  font-style: italic;
}

.theme--dark.v-application {
  background: #0f1c47 !important;
}
.theme--dark.v-tabs-items {
  background: transparent !important;
}
.nudge-up-one {
  margin-bottom: 1px;
}
</style>
