<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="background"
    dark
    flat
    height="70px"
  >
    <v-toolbar-title class="font-weight-bold ml-4 d-flex">
      <v-icon class="mr-1">{{ icon }}</v-icon>
      {{ title }}
    </v-toolbar-title>

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="info"
    ></v-progress-linear>

    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="false"
          small
          dark
          icon
          to="/"
          v-bind="attrs"
          v-on="on"
          class="ml-4"
        >
          <v-icon x-small>mdi-view-dashboard</v-icon>
        </v-btn>
      </template>
      <span>Wallboards</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="false"
          small
          dark
          icon
          to="/agents"
          v-bind="attrs"
          v-on="on"
          class="ml-4"
        >
          <v-icon x-small>fa-headset</v-icon>
        </v-btn>
      </template>
      <span>Agents</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" small dark icon @click="togglePhone">
          <v-icon v-if="open" x-small>fa-phone-slash</v-icon>
          <v-icon v-else x-small>fa-phone</v-icon>
        </v-btn>
      </template>
      <span>{{ open ? "Close" : "Open" }} softphone</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          dark
          icon
          @click="logout"
          v-bind="attrs"
          v-on="on"
          class="ml-4"
        >
          <v-icon x-small>fa-sign-out-alt</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>

    <div class="d-flex align-center ml-10 mr-4">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="require('../assets/logo.png')"
        transition="scale-transition"
        max-height="30"
        max-width="100"
      />
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  props: ["open"],
  data() {
    return {
      phoneOpen: this.open
    };
  },
  computed: {
    title() {
      return this.$route.name;
    },
    icon() {
      return this.$route.meta.icon;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("userLogout");
    },
    togglePhone() {
      this.phoneOpen = !this.phoneOpen;
      this.$emit("phoneOpen", this.phoneOpen);
    }
  }
};
</script>

<style>
.v-toolbar__title {
  line-height: 1;
}
</style>
