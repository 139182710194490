import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { db, auth } from "@/firebase";
import moment from "moment";

Vue.use(Vuex);

let flag = false;

export default new Vuex.Store({
  state: {
    user: null,
    loading: true,
    refresh: false,
    userWallboardIds: [],
    allWallboards: [],
    metrics: [],
    dataSnapshotTime: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setLoadingComplete(state) {
      state.loading = false;
    },
    setUserWallboardIds(state, payload) {
      state.userWallboardIds = payload;
    },
    setAllWallboards(state, payload) {
      state.allWallboards = payload;
    },
    setMetrics(state, payload) {
      state.metrics = payload;
    },
    setDataSnapshotTime(state, payload) {
      state.dataSnapshotTime = payload;
    },
    setRefresh(state) {
      state.refresh = true;
    }
  },
  actions: {
    userLogout({ commit }) {
      auth
        .signOut()
        .then(() => {
          commit("setUser", null);
          router.push("/login");
        })
        .catch(() => {
          commit("setUser", null);
          router.push("/login");
        });
    },
    updateUserWallboardIds({ commit, state }, ids) {
      db.collection("users")
        .doc(state.user.email)
        .set(
          {
            wallboards: ids
          },
          { merge: true }
        );
      commit("setUserWallboardIds", ids);
    },
    getMetrics({ commit, state }) {
      return new Promise(resolve => {
        db.doc("data/metrics").onSnapshot(
          doc => {
            const { metrics, dataSnapshotTime } = doc.data();

            let queueDisplayNames = [];
            state.allWallboards.forEach(wb => {
              wb.queues.forEach(q => {
                queueDisplayNames.push({
                  id: q.id,
                  display_name: q.display_name
                });
              });
            });

            let queueData = [];

            metrics.forEach(item => {
              let display_name = "";

              const getQueueDetail = queueDisplayNames.find(
                x => x.id === item.id
              );
              if (getQueueDetail !== undefined) {
                display_name = getQueueDetail.display_name;
              }

              let object = {
                id: item.id,
                name: item.queue,
                display_name
              };

              if (item.current !== undefined) {
                item.current.forEach(metric => {
                  object[`${metric.Metric.Name.toLowerCase()}`] = metric.Value;
                });
              }
              if (item.historic !== undefined) {
                item.historic.forEach(metric => {
                  object[`${metric.Metric.Name.toLowerCase()}`] = metric.Value;
                });
              }

              queueData.push(object);
            });

            commit(
              "setDataSnapshotTime",
              moment(dataSnapshotTime.toDate()).format("h:mm:ss a")
            );
            commit("setMetrics", queueData);
            resolve();
          },
          error => {
            console.log(error);
            commit("setRefresh");
          }
        );
      });
    },
    getVersionUpdate({ commit }) {
      db.collection("data")
        .doc("update")
        .onSnapshot(() => {
          if (!flag) {
            flag = true;
          } else {
            commit("setRefresh");
          }
        });
    }
  },
  getters: {
    user(state) {
      return state.user !== null && state.user !== undefined;
    },
    loading(state) {
      return state.loading;
    },
    userWallboardIds(state) {
      return state.userWallboardIds;
    },
    allWallboards(state) {
      return state.allWallboards;
    },
    metrics(state) {
      return state.metrics;
    },
    dataSnapshotTime(state) {
      return state.dataSnapshotTime;
    }
  },
  modules: {}
});
