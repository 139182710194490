<template>
  <div id="ccp-wrapper" :class="`${open ? 'open' : ''}`">
    <div id="containerDiv" class="ccp">
      <!--Amazon CCP will go here-->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Ccp",
  props: ["open"],
  data() {
    return {
      init: false
    }
  },
  mounted() {
    const connectPopup = localStorage.getItem("connectPopupManager::connect::loginPopup");
    if (connectPopup) {
      localStorage.removeItem("connectPopupManager::connect::loginPopup")
    }

    const localOpen = localStorage.getItem("ssce-ccp-open");
    if (localOpen && localOpen === "true") {
      this.$emit("phoneOpen", true);
      this.initFunc();
    }
  },
  methods: {
    initFunc() {
      this.init = true;
      window.myCPP = window.myCPP || {};

      //replace with the CCP URL for the current Amazon Connect instance
      var ccpUrl = "https://production-vocusnetworkservices.my.connect.aws/connect/ccp-v2";
      // var ccpUrl = "https://dev-vocusnetworkservices.my.connect.aws/connect/ccp-v2";

      connect.core.initCCP(containerDiv, {
        ccpUrl: ccpUrl,
        loginPopup: true,
        region: "ap-southeast-2", // REQUIRED for `CHAT`, optional otherwise
        // optional
        softphone: {
          allowFramedSoftphone: true // optional
        },
        //optional
        pageOptions: {
          enablePhoneTypeSettings: true, //optional, defaults to 'true'
          enableAudioDeviceSettings: true
        }
      });

      connect.contact(subscribeToContactEvents);

      function subscribeToContactEvents(contact) {
        window.myCPP.contact = contact;
        window.intialContactId = contact.getOriginalContactId();
      }
    }
  },
  watch: {
    open() {
      localStorage.setItem("ssce-ccp-open", this.open);
      if (this.open && !this.init) {
        this.initFunc();
      }
    }
  }
}
</script>

<style scoped>
#ccp-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 600px;
  z-index: -1;
}

#ccp-wrapper.open {
  z-index: 999;
}

#ccp-wrapper .ccp {
  opacity: 0;
  height: 600px;
  transform: translateX(50px);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

#ccp-wrapper.open .ccp {
  display: block;
  opacity: 1;
  transform: translateY(0);
  height: 100%;
}
</style>
