import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCxQiYlEv6AGdNfj0vfJMXFNnbLu7Zze3A",
  authDomain: "vocus-awsconnect-wallboard.firebaseapp.com",
  projectId: "vocus-awsconnect-wallboard",
  storageBucket: "vocus-awsconnect-wallboard.appspot.com",
  messagingSenderId: "583595223601",
  appId: "1:583595223601:web:a0ae1a85f61266d6a48310"
};

firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();

export { auth, db };
