import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import { db, auth } from "@/firebase";
import { sortBy } from "lodash";
import moment from "moment";

Vue.use(VueMeta);

Vue.config.productionTip = false;
if (process.env.NODE_ENV === "production") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

let app = "";
auth.onAuthStateChanged(async currentUser => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }

  if (currentUser) {
    let promises = [];

    promises.push(
      db
        .collection("users")
        .doc(currentUser.email.toLowerCase())
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            if (data.wallboards !== undefined && data.wallboards.length) {
              store.commit("setUserWallboardIds", data.wallboards);
            }
          } else {
            // doc.data() will be undefined in this case
            db.collection("users")
              .doc(currentUser.email.toLowerCase())
              .set({
                email: currentUser.email.toLowerCase(),
                created: moment().toDate()
              });
          }
        })
    );

    promises.push(
      db
        .collection("wallboards")
        .get()
        .then(querySnapshot => {
          let results = [];
          querySnapshot.forEach(doc => {
            let data = doc.data();
            data.id = doc.id;
            results.push(data);
          });
          store.commit("setAllWallboards", sortBy(results, "name"));
        })
    );

    await Promise.all(promises);
    await store.dispatch("getMetrics");
    store.commit("setLoadingComplete");
  }
});
